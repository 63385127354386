import revive_payload_client_4sVQNw7RlN from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/laoshi_io/web/teacher-space-web-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import sentry_client_VdOeuw25Mb from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/builds/laoshi_io/web/teacher-space-web-frontend/.nuxt/sentry-client-config.mjs";
import plugin_client_LcKgStRyi6 from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import composition_sLxaNGmlSL from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_t2GMTTFnMT from "/builds/laoshi_io/web/teacher-space-web-frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import _1_supabase_dz5agcuXQb from "/builds/laoshi_io/web/teacher-space-web-frontend/plugins/1.supabase.ts";
import _2_apis_MFzkEo5XP8 from "/builds/laoshi_io/web/teacher-space-web-frontend/plugins/2.apis.ts";
import clickOutside_ZB3Eg0wOVk from "/builds/laoshi_io/web/teacher-space-web-frontend/plugins/clickOutside.ts";
import event_bus_S6Iq1iGe5d from "/builds/laoshi_io/web/teacher-space-web-frontend/plugins/event-bus.ts";
import indicatorCustomEvent_client_35YhjGTQtV from "/builds/laoshi_io/web/teacher-space-web-frontend/plugins/indicatorCustomEvent.client.ts";
import vue_toast_4mb0A22mA8 from "/builds/laoshi_io/web/teacher-space-web-frontend/plugins/vue-toast.ts";
import vueFinalModal_D975ZryQAR from "/builds/laoshi_io/web/teacher-space-web-frontend/plugins/vueFinalModal.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_client_LcKgStRyi6,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_t2GMTTFnMT,
  _1_supabase_dz5agcuXQb,
  _2_apis_MFzkEo5XP8,
  clickOutside_ZB3Eg0wOVk,
  event_bus_S6Iq1iGe5d,
  indicatorCustomEvent_client_35YhjGTQtV,
  vue_toast_4mb0A22mA8,
  vueFinalModal_D975ZryQAR
]