import axios, { type AxiosInstance } from 'axios'
import type { ClassTaskAuditionContent, ClassTaskSentenceContent, ClassTaskTestsContent } from '~/types'
import type BaseApi from '~/apis/BaseApi'
import type { ChatMessage } from '~/types/chat'

export default class AiApi implements BaseApi {
  baseUrl: string
  axios: AxiosInstance
  constructor (baseUrl: string) {
    this.baseUrl = baseUrl
    this.axios = axios.create({
      baseURL: this.baseUrl,
      timeout: 90000
    })
  }

  public async generateTexts (data: {
    hsk?: string;
    grammarId?: number;
    vocabulary?: string;
    context?: string;
    splitWords: boolean;
  }) {
    const dataWithoutMinusOne = Object.keys(data).reduce((acc, key) => {
      const typedKey = key as keyof typeof data
      if (data[typedKey] !== -1) {
        (acc as any)[typedKey] = data[typedKey]
      }
      return acc
    }, {} as Partial<typeof data>)
    if (data.splitWords) {
      const res = await this.axios.post<ClassTaskTestsContent[]>(`${this.baseUrl}/langchain/texts`, dataWithoutMinusOne)
      return res.data
    } else {
      const res = await this.axios.post<ClassTaskSentenceContent[]>(`${this.baseUrl}/langchain/texts`, dataWithoutMinusOne)
      return res.data
    }
  }

  public async generateDialogue (data: any) {
    const res = await this.axios.post<ClassTaskAuditionContent>(`${this.baseUrl}/langchain/dialogue`, data)
    return res.data
  }

  public async generateChat (messages: ChatMessage[]) {
    const res = await this.axios.post<string>(`${this.baseUrl}/langchain/chat`, { messages })
    return res.data
  }
}
