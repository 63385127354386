import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBaseView, LazyButtonGroup, LazyHanziStrokeAnimation, LazyInput, LazyJsonEmphasise, LazyJsonError, LazyJsonQuiz, LazyJsonShuffleSentence, LazyJsonWord, LazyJsonWordExample, LazyJsonWordList, LazySuggestTopic, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["BaseView", LazyBaseView],
["ButtonGroup", LazyButtonGroup],
["HanziStrokeAnimation", LazyHanziStrokeAnimation],
["Input", LazyInput],
["JsonEmphasise", LazyJsonEmphasise],
["JsonError", LazyJsonError],
["JsonQuiz", LazyJsonQuiz],
["JsonShuffleSentence", LazyJsonShuffleSentence],
["JsonWord", LazyJsonWord],
["JsonWordExample", LazyJsonWordExample],
["JsonWordList", LazyJsonWordList],
["SuggestTopic", LazySuggestTopic],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
